import React from 'react';
import { SubscriptionType } from '../../../../../types/Subscription';
import { ReportType, useGetPdf } from '../../../hooks/useGetPdf';
import { ReportButton } from '../list/TaxBandsListButtonsBar';

export const PlacementsListButtonsBar: React.FC<{
  reportAvailable: boolean;
  id?: number;
}> = ({ reportAvailable, id }) => {
  const { getPdf } = useGetPdf({
    subscriptionType: SubscriptionType.PREMIUM,
    reportType: ReportType.TAX_BANDS,
    id: id
  });

  return (
    <div className="mb-4">
      <div>
        <div className={'inline'}></div>
        <ReportButton reportAvailable={reportAvailable} getPdf={getPdf} />
      </div>
    </div>
  );
};
