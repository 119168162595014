import React, { useEffect, useState } from 'react';
import { useWineStorageService } from '../../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { PlacementResult, TaxBandsPlacementForm } from '../../../types/WineStorage';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { defaultError } from '../../../../parcel/context/ParcelContext';
import { ToApiConverter } from '../../../../../../../services/Converters';
import { ResponseError } from '../../../../../../error/ResponseError';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';
import { SelectOption } from '../../../../../../../types/SelectOption';
import { TaxBands } from '../../../../../../../types/TaxBands';

interface Props {
  loading: boolean;
  taxBandsPlacement: Partial<TaxBandsPlacementForm>;
  updateDate: (name: string, date: string) => void;
  updateQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: ServiceError;
  onSubmit: () => void;
}

export const withAddTaxBandsPlacementService =
  <ServiceProps,>(WrappedComponent: React.ComponentType<Props>) =>
  () => {
    const service = useWineStorageService();
    const { setResult } = useWineStorageContext();
    const { taxBandsPlacementId } = useParams<RouterParams>();
    const [loading, setLoading] = useState(false);
    const [taxBandsPlacement, setTaxBandsPlacement] = useState<Partial<TaxBandsPlacementForm>>({
      placementDate: new Date(),
      placementResult: PlacementResult.USED
    });
    const [selectedTaxBands, setSelectedTaxBands] = useState<Partial<TaxBands>>();

    const { goBack } = usePushHistory();

    const [error, setError] = useState<ServiceError>(defaultError);

    const updateQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = event?.target?.value;
      setTaxBandsPlacement((prev) => ({ ...prev, quantity: parseInt(quantity) }));
    };

    const updateCheckbox = (name: string, value: boolean) => {
      setTaxBandsPlacement((prev) => ({ ...prev, [name]: value }));
    };

    const updateSelectedTaxBands = (name: string, selected: SelectOption) => {
      setTaxBandsPlacement((prev) => ({ ...prev, [name]: selected.value }));
      setSelectedTaxBands({ id: Number(selected.value), label: selected.label });
    };

    useEffect(() => {
      if (taxBandsPlacementId && taxBandsPlacement !== null) {
        setLoading(true);
        service
          .getTaxBandsPlacement(taxBandsPlacementId)
          .then((res) => {
            setTaxBandsPlacement(res);
            setSelectedTaxBands({ id: res.taxBandsId });
          })
          .finally(() => setLoading(false));
      }
    }, [taxBandsPlacementId, setTaxBandsPlacement]);

    const updateDate = (name: string, date: string) => {
      setTaxBandsPlacement((prev) => ({
        ...prev,
        [name]: date ? ToApiConverter.convertDate(date) : ''
      }));
    };

    const onSubmit = () => {
      setLoading(true);
      service
        .addTaxBandsPlacement(taxBandsPlacement)
        .then((response) => {
          setResult({ status: StatusType.loaded, payload: response });
          setLoading(false);
          setTaxBandsPlacement({});
        })
        .catch((response) => {
          setError(new ResponseError(response));
          setLoading(false);
        });
    };

    const onUpdate = () => {
      setLoading(true);
      service
        .updateTaxBandsPlacement(taxBandsPlacementId, taxBandsPlacement)
        .then(() => {
          setResult({ status: StatusType.loaded });
          setLoading(false);
          setTaxBandsPlacement({});
          goBack();
        })
        .catch((response) => {
          setError(new ResponseError(response));
          setLoading(false);
        });
    };

    const newProps = {
      loading: loading,
      taxBandsPlacement,
      updateDate,
      updateQuantity,
      updateCheckbox,
      error,
      onSubmit,
      onUpdate,
      updateSelectedTaxBands,
      selectedTaxBands
    };

    return <WrappedComponent {...newProps} />;
  };
