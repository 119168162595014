import React, { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import SelectIngredientType from './SelectIngredientType';
import { ServiceError } from '../../../../../../types/Service';
import { Ingredient } from '../../types/Ingredient';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { InputColumn } from '../../../../../../components/forms/InputColumn';
import { TextareaColumn } from '../../../../../../components/forms/TextareaColumn';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  updateIngredientType: () => void;
  error: ServiceError;
  ingredient: Ingredient;
  updateIngredient: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  loading: boolean;
  onClickBack: () => void;
}

export const SimpleIngredientForm: FC<Props> = ({
  onSubmit,
  updateIngredientType,
  error,
  ingredient,
  updateIngredient,
  loading,
  onClickBack
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/ingredients`);
  }

  return (
    <PageWrapper title={'ingredients.TITLE'} onClick={pushToBoard} returnText={'home.INGREDIENTS'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-5'}>
              <h5 className="float-right">
                {ingredient?.id
                  ? t('sidebar.nav.element.EDIT')
                  : t('sidebar.nav.element.ADD_NEW_INGREDIENT')}
              </h5>
            </div>
            <InputColumn
              label={t('ingredients.NAME')}
              type={'text'}
              name={'name'}
              maxSize={'100'}
              value={ingredient?.name}
              onChange={updateIngredient}
              error={error}
              disabled={ingredient?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectIngredientType
              value={ingredient?.type}
              name={'type'}
              label={t('ingredients.TYPE')}
              onChange={updateIngredientType}
              error={error}
              disabled={ingredient?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <TextareaColumn
              label={t('ingredients.SHORT_INFO')}
              name={'shortInfo'}
              maxSize={200}
              value={ingredient?.shortInfo}
              onChange={updateIngredient}
              error={error}
              optional
              disabled={ingredient?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <TextareaColumn
              label={t('ingredients.INFO')}
              name={'info'}
              value={ingredient?.info}
              onChange={updateIngredient}
              error={error}
              optional
              disabled={ingredient?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              {ingredient?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {ingredient?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};
