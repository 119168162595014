import { TaxBandsReadModel } from '../../../../../types/TaxBands';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';

const defaultTaxBands: TaxBandsReadModel = {
  id: undefined,
  label: '',
  initialQuantity: undefined,
  handingOverDate: undefined,
  used: undefined,
  damaged: undefined,
  destroyed: undefined,
  lost: undefined,
  returned: undefined,
  available: false,
  availableQuantity: undefined
};

export function useSelectedTaxBands({
  taxBands
}: {
  taxBands: TaxBandsReadModel[];
}): [selectedTaxBands: TaxBandsReadModel, selectTaxBands: ({ id }: { id: number }) => void] {
  const [selectedTaxBands, setSelectedTaxBands] = useState<TaxBandsReadModel>(defaultTaxBands);
  const { taxBandsId } = useParams<RouterParams>();

  const selectTaxBands = useCallback(
    ({ id }: { id: number }) => {
      setSelectedTaxBands(taxBands.find((bands) => bands.id === id));
    },
    [taxBands]
  );

  useEffect(() => {
    console.log({ taxBandsId });
    if (taxBandsId) {
      selectTaxBands({ id: Number(taxBandsId) });
    }
  }, [taxBandsId, selectTaxBands]);

  return [selectedTaxBands, selectTaxBands];
}
