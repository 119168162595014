import React from 'react';
import { ColoredCard } from './ColoredCard';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from '../../../../types/Subscription';

export const StartBoardCardsMenu: React.FC<{ small?: boolean }> = ({ small }) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <ColoredCard
        color="green"
        icon="fa-chart-area"
        title={t('start.vineyard.title')}
        subtitle={t('start.vineyard.subtitle')}
        path={'/mv/board/summary'}
        small={small}
      />
      <ColoredCard
        color="info"
        icon="fa-cubes"
        title={t('start.winery.title')}
        path={'/mv/board/production'}
        subtitle={t('start.winery.subtitle')}
        small={small}
      />
      <ColoredCard
        color="warning"
        icon="fa-door-open"
        title={t('start.storage.title')}
        path={'/mv/board/storage'}
        subtitle={t('start.storage.subtitle')}
        subscriptionRequired={SubscriptionType.PREMIUM}
        small={small}
      />
      <ColoredCard
        color="gradient-blue-orange"
        icon="icon-layers"
        title={t('start.taxBands.title')}
        path={'/mv/board/tax_bands'}
        subtitle={t('start.taxBands.subtitle')}
        subscriptionRequired={SubscriptionType.PREMIUM}
        small={small}
      />
      <ColoredCard
        color="gradient-blue-green"
        icon="fa-compact-disc"
        title={t('start.bottles.title')}
        path={'/mv/board/bottles'}
        subtitle={t('start.bottles.subtitle')}
        subscriptionRequired={SubscriptionType.PREMIUM}
        small={small}
      />
      <ColoredCard
        color="gradient-blue-purple"
        icon="fa-copy"
        title={t('start.reports.title')}
        path={'/mv/reports'}
        subtitle={t('start.reports.subtitle')}
        subscriptionRequired={SubscriptionType.PREMIUM}
        small={small}
      />
      <ColoredCard
        color="danger"
        icon="fa-mobile"
        title={t('start.tanks.title')}
        path={'/mv/board/tanks'}
        subtitle={t('start.tanks.subtitle')}
        small={small}
      />
      <ColoredCard
        color="yellow"
        icon="fa-flask"
        title={t('start.ingredients.title')}
        path={'/mv/board/ingredients'}
        subtitle={t('start.ingredients.subtitle')}
        small={small}
      />
      <ColoredCard
        color="dark"
        icon="fa-street-view"
        title={t('start.account.title')}
        subtitle={t('start.account.subtitle')}
        path={'/mv/account'}
        small={small}
      />
    </div>
  );
};
