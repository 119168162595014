import useCrudeApi from '../../../../../lib/useCrudeApi';
import { TaxBands, TaxBandsInfo } from '../../../../../types/TaxBands';

export const useTaxBandsService = () => {
  const {
    getList,
    post,
    getAllWithParams,
    get: getPlacementsForTaxBands,
    del
  } = useCrudeApi<TaxBands>(`/ajax/tax_bands`);
  const getAvailableList = (showAvailable = true) => {
    return getAllWithParams(new Map<string, boolean>([['available', showAvailable]]));
  };

  const { post: addTaxBandsPlacement, delByTwoIds: removePlacement } = useCrudeApi(
    'ajax/tax_bands/placement'
  );

  const postWithInitialValues = (body: TaxBandsInfo) => {
    return post(body, new Map<string, boolean>([['withInitValues', true]]));
  };

  return {
    getList,
    getAvailableList,
    post,
    postWithInitialValues,
    getPlacementsForTaxBands,
    del,
    removePlacement,
    addTaxBandsPlacement
  };
};
