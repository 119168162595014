import React from 'react';
import { useTranslation } from 'react-i18next';
import { BottleFormRow } from './BottleFormRow';
import { Bottle } from '../../../../../winery/components/wine_storage/types/WineStorage';
import { ServiceError } from '../../../../../../types/Service';
import { useUpdateBottle } from '../../hooks/useUpdateBottle';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="mb-2"></div>
      <small className="text-muted ">{t('bottles.board.add')}</small>
    </div>
  );
};

export const BottleForm: React.FC<{
  addBottle: (bottle: Bottle) => void;
  loading: boolean;
  error?: ServiceError;
}> = ({ addBottle, loading, error }) => {
  const [bottle, updateField] = useUpdateBottle();
  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header />
          <BottleFormRow
            addBottle={addBottle}
            onChange={updateField}
            error={error}
            bottle={bottle}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};
