import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useMapSearch<K, V>({
  entities,
  field
}: {
  entities: Map<K, V[]>;
  field: string | string[];
}): [result: Map<K, V[]>, search: (value: string) => void] {
  const [result, setResult] = useState<Map<K, V[]>>(entities);
  const { t } = useTranslation();

  useEffect(() => {
    setResult(entities);
  }, [entities]);

  function search(value: string) {
    const searchValue = value.toLowerCase().trim();

    const doesItemMatch = (item: V): boolean => {
      if (typeof field === 'string') {
        return getEnumName(item, field)?.toLowerCase().includes(searchValue) ?? false;
      }
      return field.some((f) => getEnumName(item, f)?.toLowerCase().includes(searchValue));
    };

    const filteredEntries = new Map();

    for (const [key, items] of entities.entries()) {
      if (items.some(doesItemMatch)) {
        filteredEntries.set(key, items);
      }
    }

    setResult(filteredEntries);
  }

  function getEnumName(item: V, field: string) {
    if (typeof item[field] === 'boolean') {
      return item[field] ? t('answer.yes') : t('answer.no');
    }
    if (field === 'wineColor') {
      return item[field] ? t(`wine.COLOR.${item[field]}`) : 'moszcz';
    }
    if (field === 'wineTaste') {
      return t(`wine.TASTE.${item[field]}`);
    }
    if (field === 'grapeColor') {
      return t(`wine.COLOR.${item[field]}`);
    }
    if (field === 'ingredientType') {
      return t(`ingredients.TYPE.${item['type']}`);
    }
    if (field === 'placementResult') {
      return t(`placement.placementResult.${item[field]}`);
    }
    if (item[field] && typeof item[field] === 'object') {
      return item[field]['name'];
    }
    return item[field]?.toString();
  }

  return [result, search];
}
