import { useMemo, useState } from 'react';
import { useUpdateEffect } from './useUpdateEffect';
import { Pagination } from '../types/Pagination';

function usePagination<K, V>(
  data?: Map<K, V>,
  itemsPerPage = 0,
  initPage = 1
): Pagination<Map<K, V>> {
  const [currentPage, setCurrentPage] = useState(initPage);
  const safeData = data instanceof Map ? data : new Map(); // Ensure data is always a Map
  const maxPage = itemsPerPage < 0 ? 1 : Math.ceil(safeData.size / itemsPerPage);
  const total = useMemo(() => safeData.size, [safeData.size]);

  function getCurrentResults(): Map<K, V> {
    if (itemsPerPage < 0) {
      return safeData;
    }

    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    const result = new Map<K, V>();

    const entriesArray = Array.from(safeData.entries());
    const paginatedEntries = entriesArray.slice(begin, end);

    for (const [key, value] of paginatedEntries) {
      result.set(key, value);
    }

    return result;
  }

  useUpdateEffect(() => {
    if (currentPage > maxPage) {
      jump(maxPage);
    }
    if (currentPage === 0) {
      jump(1);
    }
  }, [itemsPerPage, currentPage, maxPage]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, getCurrentResults, currentPage, maxPage, total };
}

export default usePagination;
