import { useEffect, useState } from 'react';
import { Service, ServiceWorking, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { SelectOption } from '../../../../../types/SelectOption';
import { useTaxBandsService } from './useTaxBandsService';
import { TaxBandsReadModel } from '../../../../../types/TaxBands';

const useTaxBandsReceiver = (value?) => {
  const service = useTaxBandsService();
  const [result, setResult] = useState<Service<TaxBandsReadModel[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value && options.length) {
      setSelected(options.find((option) => option.value === value.id));
    }
    const workingResult = result as ServiceWorking<TaxBandsReadModel[]>;
    if (!workingResult?.payload) {
      service
        ?.getAvailableList()
        .then((response) => {
          setResult({ status: StatusType.loaded, payload: response });
          const taxBandsOptions =
            response?.map((taxBands) => ({
              value: taxBands.id,
              label: `${taxBands.label} - ${taxBands.availableQuantity} szt.`
            })) || [];
          setOptions(taxBandsOptions);
        })
        .catch((error) => setResult(new ResponseError(error)));
    }
  }, [value, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected };
};

export default useTaxBandsReceiver;
