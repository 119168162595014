import React, { useState } from 'react';
import useClickOutside from '../../../../../../hooks/useClickOutside';
import { Color } from '../../../../../../components/common/enums/Color';

type DropdownActionButton = {
  mainAction: DropdownButtonAction;
  dropdownActions: DropdownButtonAction[];
  bottomAction?: DropdownButtonAction;
  color?: Color;
};

export type DropdownButtonAction = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  display?: 'block' | 'none';
  color?: Color;
};

export const DropdownActionButton: React.FC<DropdownActionButton> = ({
  mainAction,
  dropdownActions,
  bottomAction,
  color = Color.LightBlue
}) => {
  const [open, setOpen] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setOpen(false);
  });

  function handleClick(callback: () => void) {
    setOpen(false);
    callback();
  }
  return (
    <div className={'btn-group ' + (open ? 'show' : '')} ref={clickOutsideRef}>
      <button
        className={'btn btn-sm btn-' + mainAction.color || color}
        type="button"
        onClick={() => handleClick(mainAction.onClick)}
      >
        {mainAction.label}
      </button>
      <button
        className={'btn btn-sm dropdown-toggle btn-' + mainAction.color || color}
        type="button"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <span className="sr-only">info</span>
      </button>
      <div className={'dropdown-menu ' + (open ? 'show' : '')} role="menu">
        {dropdownActions.map((action, index) => (
          <button
            className={'dropdown-item ' + (action.color ? `text-${action.color}` : '')}
            disabled={action.disabled}
            style={{ display: action.display || 'block' }}
            key={index}
            onClick={() => handleClick(action.onClick)}
          >
            {action.label}
          </button>
        ))}
        {bottomAction ? (
          <>
            <div className="dropdown-divider"></div>
            <button
              className={
                'dropdown-item ' + (bottomAction.color ? `text-${bottomAction.color}` : '')
              }
              disabled={bottomAction.disabled}
              onClick={() => handleClick(bottomAction.onClick)}
            >
              {bottomAction.label}
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};
