import { useCallback, useState } from 'react';
import { TaxBandsInfo } from '../../../../../types/TaxBands';
import { TaxBandsUpdateFormFieldValue } from '../form/TaxBandsForm';

export function useUpdateTaxBands(): [
  taxBands: TaxBandsInfo,
  updateField: ({ name, value }: TaxBandsUpdateFormFieldValue) => void
] {
  const [taxBands, setTaxBands] = useState<TaxBandsInfo>({
    label: '',
    initialQuantity: undefined,
    handingOverDate: undefined,
    used: undefined,
    damaged: undefined,
    destroyed: undefined,
    lost: undefined,
    returned: undefined
  });

  const updateField = useCallback(({ name, value }: TaxBandsUpdateFormFieldValue) => {
    setTaxBands((prev) => ({
      ...prev,
      [name]: value
    }));
  }, []);

  return [taxBands, updateField];
}
