import React, { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { WineEntryToWineProductionProps } from './withWineEntryToWineProductionServiceHOC';
import { SelectWineEntryType } from './SelectWineEntryType';
import { SelectWineEntry } from './SelectWineEntry';
import { useResponsiveContext } from '../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../hooks/useToggleAndGoToRef';
import { useAuthContext } from '../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { useWineProductionContext } from '../../context/WineProductionContext';
import { DivDropped } from '../../../../../../components/common/dropdowns/button-and-div/DivDropped';
import { DatePickerColumn } from '../../../../../../components/forms/DatePickerColumn';
import { InputColumn } from '../../../../../../components/forms/InputColumn';

export const WineEntryFormFields: FC<WineEntryToWineProductionProps> = ({
  wineEntry,
  numberOdWineEntriesInWine,
  actions: { onChange, updateDate, updateSelect, updateSelectWineEntry, onSubmit, key },
  error
}) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();
  const { wineProduction } = useWineProductionContext();
  const { isMobile } = useResponsiveContext();
  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    itemIndexToHighlight: 4
  });
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);

  function handleOpen() {
    if (numberOdWineEntriesInWine > 0 && !isValidSubscription()) {
      return;
    }
    toggleOpen();
  }

  return (
    <Card
      key={key}
      innerRef={currentRef}
      className={isOpen ? 'border-info-dark form' : 'bg-info-dark form'}
    >
      <CardBody className={'pt-2 pb-1'}>
        <div
          onClick={() => handleOpen()}
          style={{ cursor: 'pointer', height: '1.5em' }}
          className={'pt-1'}
        >
          <i className="float-left">
            {!accessInfo.hasNormalAccess && numberOdWineEntriesInWine > 0 ? (
              <em className={'icon-lock'} />
            ) : (
              <em
                className={
                  isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
                }
              />
            )}
            <sup className="text-yellow ml-1"> pro</sup>
          </i>
          <h5 className={'m-0 mb-2 float-right ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
            {wineProduction?.wineEntries?.length
              ? t('wineProduction.info.ADD_ANOTHER_WINE_ENTRY')
              : t(
                  wineEntry?.id
                    ? 'wineProduction.info.EDIT_WINE_ENTRY'
                    : 'wineProduction.info.ADD_NEW_WINE_ENTRY'
                )}
          </h5>
        </div>
        <DivDropped open={isOpen}>
          <div className={'mt-2'}>
            <SelectWineEntryType
              value={wineEntry?.entryType}
              name={'entryType'}
              label={t('wineEntry.ENTITY_TYPE')}
              onChange={updateSelect}
              error={error}
            />
            {wineEntry?.entryType ? (
              <SelectWineEntry
                label={t('wineEntry.WINE_ENTRY')}
                onChange={updateSelectWineEntry}
                entryType={wineEntry?.entryType}
                key={wineEntry?.entryType}
                wineEntry={wineEntry}
                name={'wineEntry'}
                error={error}
              />
            ) : null}
            <DatePickerColumn
              label={t('wineEntry.ADDED_TO_PRODUCTION')}
              name={'addedToProduction'}
              value={wineEntry.addedToProduction}
              onChange={updateDate}
              error={error}
              showTimeSelect
            />
            <InputColumn
              label={t('wineEntry.LITERS')}
              type={'number'}
              name={'liters'}
              value={wineEntry?.liters === 0 ? '' : wineEntry?.liters}
              onChange={onChange}
              error={error}
              maxSize={wineEntry?.liters}
              placeholder={
                wineEntry?.wine || wineEntry?.wineProduction
                  ? `Dostępne litry: ${
                      wineEntry?.wine?.liters || wineEntry?.wineProduction?.liters
                    }`
                  : ''
              }
            />
            <FormErrorMessage error={error} messageType={'details'} />
            <div className={'bg-secondary text-center pb-0'}>
              <Button
                color="info"
                className="btn-square btn-sm"
                onClick={wineEntry?.id ? onSubmit?.update : onSubmit?.save}
              >
                {wineEntry?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            </div>
          </div>
        </DivDropped>
      </CardBody>
    </Card>
  );
};
