import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bottle } from '../../../../../winery/components/wine_storage/types/WineStorage';
import { InputColumn } from '../../../../../../components/forms/InputColumn';
import { ServiceError } from '../../../../../../types/Service';
import { BottleUpdateFormFieldValue } from '../../hooks/useUpdateBottle';
import { TextareaColumn } from '../../../../../../components/forms/TextareaColumn';

interface IProps {
  error?: ServiceError;
  onChange: (value: BottleUpdateFormFieldValue) => void;
  bottle: Bottle;
  addBottle: (bottle: Bottle) => void;
}

export const BottleFormRow: React.FC<IProps> = ({ error, onChange, bottle, addBottle }) => {
  const { t } = useTranslation();
  return (
    <div className="form-group row">
      <div className="col-md-12">
        <div className="form-row">
          <InputColumn
            label={t('bottles.table.name')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'name'}
            value={bottle.name}
            placeholder={t('bottles.table.name')}
            error={error}
            numberOfColumnsInRow={3}
          />
          <InputColumn
            label={t('bottles.table.capacity')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'capacity'}
            value={bottle.capacity}
            placeholder={t('bottles.table.capacity')}
            error={error}
            type={'number'}
            numberOfColumnsInRow={3}
          />
          <TextareaColumn
            label={t('bottles.table.info')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'info'}
            value={bottle.info}
            placeholder={t('bottles.table.info')}
            error={error}
            numberOfColumnsInRow={3}
          />
        </div>
        <button className="btn btn-primary" type="button" onClick={() => addBottle(bottle)}>
          {t('bottles.form.submit')}
        </button>
      </div>
    </div>
  );
};
