import { useCallback, useEffect, useState } from 'react';
import { Bottle } from '../../../../winery/components/wine_storage/types/WineStorage';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';

export const defaultBottle: Bottle = {
  id: undefined,
  name: undefined,
  capacity: undefined,
  info: undefined,
  liveStatus: undefined,
  forBottling: undefined,
  approximateQuantity: undefined
};

export function useSelectedBottle({
  bottles
}: {
  bottles: Bottle[];
}): [
  selectedBottle: Bottle,
  selectTaxBands: ({ id, bottle }: { id: number; bottle?: Bottle }) => void
] {
  const [selectedBottle, setSelectedBottle] = useState<Bottle>(defaultBottle);
  const { bottleId } = useParams<RouterParams>();

  const selectBottle = useCallback(
    ({ id, bottle }: { id: number; bottle?: Bottle }) => {
      if (bottle) {
        setSelectedBottle(bottle);
      } else {
        setSelectedBottle(bottles.find((bottle) => bottle.id === id));
      }
    },
    [bottles]
  );

  useEffect(() => {
    console.log(bottleId);
    if (bottleId) {
      selectBottle({ id: Number(bottleId) });
    }
  }, [bottleId, selectBottle]);

  return [selectedBottle, selectBottle];
}
