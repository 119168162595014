import React from 'react';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import PageWrapper from '../../../../layouts/PageWrapper';
import { BottlesListButtonsBar } from './components/list/BottlesListButtonsBar';
import { useBottles } from './hooks/useBottles';
import { BottlesListTable } from './components/list/BottlesListTable';
import { BottleForm } from './components/form/BottleForm';
import { BottleButtonsBar } from './components/bottle/BottleButtonsBar';
import { BottleSelected } from './components/bottle/BottleSelected';
import { StorageWinesListTable } from '../StorageSummaryPage/StorageWinesListTable';
import { useTranslation } from 'react-i18next';

export const BottlesSummaryPages = () => {
  const { pushHistory } = usePushHistory();
  const {
    state: {
      bottles,
      tab,
      loading,
      magicNumber,
      showArchivedBottles,
      selectedBottle,
      selectedBottleMode,
      error,
      wines,
      loadingWines
    },
    actions: {
      toggleShowArchivedBottles,
      addBottle,
      handleChangeTab,
      removeBottle,
      selectBottle,
      archiveBottle,
      updateBottle,
      toggleForBottling,
      toggleSelectedBottleMode
    }
  } = useBottles();

  const { t } = useTranslation();
  return (
    <PageWrapper
      title={'home.BOTTLES'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-12">
          <BottlesListButtonsBar reportAvailable={true} onClick={handleChangeTab} activeTab={tab} />
          {tab === 'list' ? (
            <BottlesListTable
              loading={loading}
              bottles={bottles}
              showArchivedBottles={showArchivedBottles}
              toggleShowArchivedBottles={toggleShowArchivedBottles}
              selectBottle={selectBottle}
              selectedBottle={selectedBottle}
              key={bottles.length + (showArchivedBottles + '') + bottles?.[0]?.id + magicNumber}
            />
          ) : null}
          {tab === 'form' ? (
            <BottleForm loading={loading} addBottle={addBottle} error={error} />
          ) : null}
          {selectedBottle?.id ? (
            <>
              <BottleButtonsBar
                reportAvailable={true}
                id={selectedBottle.id}
                selectedBottle={selectedBottle}
                removeBottle={removeBottle}
                archiveBottle={archiveBottle}
                toggleForBottling={toggleForBottling}
                toggleSelectedBottleMode={toggleSelectedBottleMode}
                selectedBottleMode={selectedBottleMode}
                key={selectedBottle.id + selectedBottle.liveStatus + magicNumber}
              />
              <div className={'row'}>
                <div className={'col-md-5'}>
                  <BottleSelected
                    selectedBottle={selectedBottle}
                    selectedBottleMode={selectedBottleMode}
                    updateBottle={updateBottle}
                    loading={loading}
                    error={error}
                    key={selectedBottle.id + selectedBottle.liveStatus + magicNumber}
                  />
                </div>
                <div className={'col-md-7'}>
                  <StorageWinesListTable
                    wines={wines}
                    loading={loadingWines}
                    title={t('bottles.wineStorages.table')}
                    onlyForBottlesFields
                    key={selectedBottle.id + selectedBottle.liveStatus + magicNumber}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
};
