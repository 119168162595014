import { useHistory, useLocation } from 'react-router-dom';

export const usePushHistory = (): {
  pushHistory: (path: string, from?: string) => void;
  goBack: (fallbackPath?: string) => void;
} => {
  const history = useHistory();
  const location = useLocation();

  const pushHistory = (path: string, from?: string) => {
    const newLocation = {
      ...location,
      state: { from: from || history.location.pathname },
      pathname: path
    };

    history.push(newLocation);
  };

  const goBack = (fallbackPath = location?.pathname) => {
    history.push(history?.location?.state?.['from'] || fallbackPath);
  };

  return { pushHistory, goBack };
};
