import { useEffect, useState } from 'react';
import { Service, ServiceWorking, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { SelectOption } from '../../../../../types/SelectOption';
import { Bottle } from '../types/WineStorage';
import { useBottleService } from '../../../../home/components/BottlesSummaryPages/hooks/useBottleService';

const useBottlesForBottlingReceiver = (value?) => {
  const service = useBottleService();
  const [result, setResult] = useState<Service<Bottle[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value && options.length) {
      setSelected(options.find((option) => option.value === value.id));
    }
    const workingResult = result as ServiceWorking<Bottle[]>;
    if (!workingResult?.payload) {
      service
        ?.getListForBottling()
        .then((response: Bottle[]) => {
          setResult({ status: StatusType.loaded, payload: response });
          const bottlesOptions =
            response?.map((bottle) => ({
              value: bottle.id,
              label: `${bottle.name} - ${bottle.capacity} l.`
            })) || [];
          setOptions(bottlesOptions);
        })
        .catch((error) => setResult(new ResponseError(error)));
    }
  }, [value, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected };
};

export default useBottlesForBottlingReceiver;
