import React from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../components/PaginationComponent';
import { useSearch } from '../../../../../hooks/useSearch';
import { SearchComponent } from '../../../../../components/common/search/SearchComponent';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import { TaxBandsReadModel } from '../../../../../types/TaxBands';
import { TaxBandsRow } from './TaxBandsRow';
import { Pagination } from '../../../../../types/Pagination';

const Header = ({
  search,
  showAllTaxBands,
  toggleShowAllTaxBands
}: {
  search: (value: string) => void;
  showAllTaxBands: boolean;
  toggleShowAllTaxBands: () => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();

  function getMessage() {
    if (isMobile) return '';
    return showAllTaxBands
      ? t('taxBands.board.show.available.btn')
      : t('taxBands.board.show.inAvailable.btn');
  }

  return (
    <>
      <small className="text-muted float-right">{t('taxBands.board.list')}</small>
      <div className="float-left">
        <SearchComponent search={search} />
        <button
          title={
            showAllTaxBands
              ? t('taxBands.board.show.available')
              : t('taxBands.board.show.inAvailable')
          }
          className={'ml-2 btn btn-sm ' + (showAllTaxBands ? 'btn-info' : 'btn-outline-info')}
          onClick={toggleShowAllTaxBands}
        >
          <em className="fa-1x fas fa-archive mr-1"></em> {isMobile ? null : getMessage()}
        </button>
      </div>
    </>
  );
};

const TableSection = ({ children }: { children: React.ReactNode }) => (
  <div className="dataTables_wrapper dt-bootstrap4 no-footer">
    <div className="row">
      <div className="col-sm-12 table-responsive">
        <table className="table w-100 no-footer table-hover table-sm" style={{ width: 1068 }}>
          {children}
        </table>
      </div>
    </div>
  </div>
);

const THead = ({ short, shorter }: { short: boolean; shorter: boolean }) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr role="row">
        <th style={{ minWidth: '140px', width: 215 }}>{t('taxBands.table.label')}</th>
        <th className={'text-center'} style={{ width: 85 }}>
          {t('taxBands.table.initialQuantity')}
        </th>
        <th className={'text-center'} style={{ width: 85 }}>
          {t('taxBands.table.availableQuantity')}
        </th>
        {!shorter ? (
          <th className={'text-center'} style={{ width: 85 }}>
            {t('taxBands.table.handingOverDate')}
          </th>
        ) : null}
        {!short ? (
          <>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('taxBands.table.used')}
            </th>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('taxBands.table.damaged')}
            </th>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('taxBands.table.lost')}
            </th>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('taxBands.table.destroyed')}
            </th>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('taxBands.table.returned')}
            </th>
          </>
        ) : null}
      </tr>
    </thead>
  );
};

const TBody = ({
  short,
  shorter,
  pagination,
  selectTaxBands,
  selectedTaxBands
}: {
  short: boolean;
  shorter: boolean;
  pagination: Pagination<TaxBandsReadModel[]>;
  selectTaxBands: ({ id }: { id: number }) => void;
  selectedTaxBands: TaxBandsReadModel;
}) => (
  <tbody>
    {pagination.getCurrentResults().map((taxBandItem) => {
      return (
        <TaxBandsRow
          taxBands={taxBandItem}
          onClick={() => selectTaxBands({ id: taxBandItem.id })}
          key={taxBandItem.id}
          short={short}
          shorter={shorter}
          selectedTaxBands={selectedTaxBands}
        />
      );
    })}
    {pagination.getCurrentResults().length === 0 ? (
      <tr>
        <td colSpan={100} className="text-center">
          Brak danych
        </td>
      </tr>
    ) : null}
  </tbody>
);

export const TaxBandsListTable: React.FC<{
  taxBands: TaxBandsReadModel[];
  loading: boolean;
  showAllTaxBands: boolean;
  toggleShowAllTaxBands: () => void;
  selectTaxBands: ({ id }: { id: number }) => void;
  selectedTaxBands: TaxBandsReadModel;
}> = ({
  taxBands,
  loading,
  showAllTaxBands,
  toggleShowAllTaxBands,
  selectTaxBands,
  selectedTaxBands
}) => {
  const { isMobile, isSmallMobile } = useResponsiveContext();

  const [result, search] = useSearch<TaxBandsReadModel>({
    entities: taxBands,
    field: [
      'label',
      'handingOverDate',
      'initialQuantity',
      'used',
      'damaged',
      'lost',
      'destroyed',
      'returned'
    ]
  });
  const pagination = usePagination(result, 5);
  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header
            search={search}
            showAllTaxBands={showAllTaxBands}
            toggleShowAllTaxBands={toggleShowAllTaxBands}
          />
          <TableSection>
            <THead short={isMobile} shorter={isSmallMobile} />
            <TBody
              short={isMobile}
              shorter={isSmallMobile}
              pagination={pagination}
              selectTaxBands={selectTaxBands}
              selectedTaxBands={selectedTaxBands}
            />
          </TableSection>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
