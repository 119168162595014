import React from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { TaxBandsListTable } from './list/TaxBandsListTable';
import { TaxBandsListButtonsBar } from './list/TaxBandsListButtonsBar';
import { TaxBandsForm } from './form/TaxBandsForm';
import { useTaxBands } from './hooks/useTaxBands';
import { TaxBandsSelected } from './placements/TaxBandsSelected';
import { usePlacementForTaxBands } from './hooks/usePlacementsForTaxBands';
import { PlacementFormComponent } from './placement/PlacementFormComponent';
import { PlacementsListButtonsBar } from './placements/PlacementsListButtonsBar';

export const TaxBandsSummaryPage = () => {
  const { pushHistory } = usePushHistory();
  const {
    state: {
      taxBands,
      tab,
      showAllTaxBands,
      showAllFields,
      loading,
      error,
      selectedTaxBands,
      magicNumber
    },
    actions: {
      addTaxBands,
      toggleShowAllTaxBands,
      handleChangeTab,
      toggleShowAllFields,
      removeTaxBands,
      selectTaxBands,
      getList: fetchTaxBandsList
    }
  } = useTaxBands();

  const {
    placements,
    loading: loadingPlacements,
    error: placementsError,
    getPlacementsForTaxBands,
    removePlacement
  } = usePlacementForTaxBands({ taxBandsId: selectedTaxBands?.id, fetchTaxBandsList });

  return (
    <PageWrapper
      title={'home.TAX_BANDS'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-12">
          <TaxBandsListButtonsBar
            reportAvailable={taxBands.length > 0}
            onClick={handleChangeTab}
            activeTab={tab}
          />
          {tab === 'list' ? (
            <TaxBandsListTable
              loading={loading}
              taxBands={taxBands}
              showAllTaxBands={showAllTaxBands}
              toggleShowAllTaxBands={toggleShowAllTaxBands}
              selectTaxBands={selectTaxBands}
              selectedTaxBands={selectedTaxBands}
              key={taxBands.length + (showAllTaxBands + '') + taxBands?.[0]?.id + magicNumber}
            />
          ) : null}
          {tab === 'form' ? (
            <TaxBandsForm
              loading={loading}
              addTaxBands={addTaxBands}
              showAllFields={showAllFields}
              toggleShowAllFields={toggleShowAllFields}
              error={error}
            />
          ) : null}

          {tab === 'addPlacement' ? (
            <PlacementFormComponent
              fetchTaxBandsList={fetchTaxBandsList}
              getPlacementsForTaxBands={getPlacementsForTaxBands}
              selectTaxBands={selectTaxBands}
            />
          ) : null}

          {selectedTaxBands?.id ? (
            <>
              <PlacementsListButtonsBar reportAvailable={true} id={selectedTaxBands.id} />
              <TaxBandsSelected
                selectedTaxBands={selectedTaxBands}
                placements={placements}
                loading={loadingPlacements}
                error={placementsError || error}
                removeTaxBands={removeTaxBands}
                removePlacement={removePlacement}
                disabledRemove={loadingPlacements}
              />
            </>
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
};
