export type NumberOdColumnsInRow = 1 | 2 | 3 | 4 | 6;

export function useFormFieldColumnCount({
  numberOfColumnsInRow
}: {
  numberOfColumnsInRow: NumberOdColumnsInRow;
}) {
  function getColClass() {
    switch (numberOfColumnsInRow) {
      case 1:
        return 'col-lg-12';
      case 2:
        return 'col-lg-6';
      case 3:
        return 'col-lg-4';
      case 4:
        return 'col-lg-3';
      case 6:
        return 'col-lg-2';
      default:
        return 'col-lg-12';
    }
  }

  return getColClass();
}
