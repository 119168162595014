import { TaxBandsReadModel } from '../../../../../types/TaxBands';
import { useCallback, useState } from 'react';

const defaultTaxBands: TaxBandsReadModel = {
  id: undefined,
  label: '',
  initialQuantity: undefined,
  handingOverDate: undefined,
  used: undefined,
  damaged: undefined,
  destroyed: undefined,
  lost: undefined,
  returned: undefined,
  available: false,
  availableQuantity: undefined
};

export function useSelectedTaxBands({
  taxBands
}: {
  taxBands: TaxBandsReadModel[];
}): [selectedTaxBands: TaxBandsReadModel, selectTaxBands: ({ id }: { id: number }) => void] {
  const [selectedTaxBands, setSelectedTaxBands] = useState<TaxBandsReadModel>(defaultTaxBands);

  const selectTaxBands = useCallback(
    ({ id }: { id: number }) => {
      setSelectedTaxBands(taxBands.find((bands) => bands.id === id));
    },
    [taxBands]
  );

  return [selectedTaxBands, selectTaxBands];
}
