import { useCallback, useEffect, useState } from 'react';
import { ResponseError } from '../../../../error/ResponseError';
import log from 'loglevel';
import { TaxBandsPlacementListPresentationData } from '../../../../winery/components/wine_storage/types/WineStorage';
import { useTaxBandsService } from './useTaxBandsService';
import { ServiceError } from '../../../../../types/Service';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../notifications/useConfirmation';

export type PlacementsForTaxBandsProps = {
  placements: Map<string, TaxBandsPlacementListPresentationData[]>;
  loading: boolean;
  error: ServiceError;
  getPlacementsForTaxBands?: (taxBandsId: number) => void;
  removePlacement: ({
    placementId,
    wineStorageId
  }: {
    placementId: number;
    wineStorageId: number;
  }) => void;
};

export function usePlacementForTaxBands({
  taxBandsId,
  fetchTaxBandsList
}: {
  taxBandsId: number;
  fetchTaxBandsList: () => void;
}): PlacementsForTaxBandsProps {
  const [placements, setPlacements] =
    useState<Map<string, TaxBandsPlacementListPresentationData[]>>();
  const [loading, setLoading] = useState(false);
  const service = useTaxBandsService();
  const [error, setError] = useState<ServiceError>();

  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const removePlacement = ({
    placementId,
    wineStorageId
  }: {
    placementId: number;
    wineStorageId: number;
  }) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemovePlacement({ placementId, wineStorageId })
    });
  };

  const getPlacementsForTaxBands = useCallback(
    async (taxBandsId: number) => {
      log.debug('getPlacementsForTaxBands', taxBandsId);
      try {
        setLoading(true);
        setError(null);
        const result = await service.getPlacementsForTaxBands(taxBandsId);
        setPlacements(result);
      } catch (e) {
        setError(new ResponseError(e));
        log.error('Cannot get placements for Tax Bands ', taxBandsId, e);
      } finally {
        setLoading(false);
      }
    },
    [taxBandsId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRemovePlacement = useCallback(
    async ({ placementId, wineStorageId }: { placementId: number; wineStorageId: number }) => {
      log.debug('removePlacement', placementId, wineStorageId);
      try {
        setLoading(true);
        setError(null);
        await service.removePlacement(placementId, wineStorageId);
        // await getPlacementsForTaxBands(taxBandsId);
        // await fetchTaxBandsList();
        await Promise.all([getPlacementsForTaxBands(taxBandsId), fetchTaxBandsList()]);
      } catch (e) {
        setError(new ResponseError(e));
        log.error('Cannot get placements for Tax Bands ', taxBandsId, e);
      } finally {
        setLoading(false);
      }
    },
    [taxBandsId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!taxBandsId) return;
    getPlacementsForTaxBands(taxBandsId);
  }, [taxBandsId, getPlacementsForTaxBands]);

  return { placements, loading, error, getPlacementsForTaxBands, removePlacement };
}
