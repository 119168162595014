import React, { useState } from 'react';
import { SelectOption } from '../../../../../types/SelectOption';
import {
  PlacementForm,
  PlacementResult
} from '../../../../winery/components/wine_storage/types/WineStorage';
import { TaxBands } from '../../../../../types/TaxBands';
import { ToApiConverter } from '../../../../../services/Converters';
import { ResponseError } from '../../../../error/ResponseError';
import { useTaxBandsService } from './useTaxBandsService';
import { ServiceError } from '../../../../../types/Service';
import { defaultError } from '../../../../winery/components/parcel/context/ParcelContext';

const defaultTaxBandsPlacement: PlacementForm = {
  placementDate: new Date(),
  placementResult: PlacementResult.DAMAGED,
  taxBandsId: null,
  quantity: null
};

export const useUpdatePlacementForm = ({
  fetchTaxBandsList,
  getPlacementsForTaxBands,
  selectTaxBands
}: {
  selectTaxBands: ({ id }: { id: number }) => void;
  fetchTaxBandsList: () => void;
  getPlacementsForTaxBands: (taxBandsId: number) => void;
}) => {
  const service = useTaxBandsService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ServiceError>(defaultError);
  const [taxBandsPlacement, setTaxBandsPlacement] =
    useState<PlacementForm>(defaultTaxBandsPlacement);
  const [selectedTaxBands, setSelectedTaxBands] = useState<Partial<TaxBands>>();
  const updateQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = event?.target?.value;
    setTaxBandsPlacement((prev) => ({ ...prev, quantity: parseInt(quantity) }));
  };

  const updateSelect = (name: string, selected: SelectOption) => {
    setTaxBandsPlacement((prev) => ({ ...prev, [name]: selected.value }));
  };

  const updateSelectedTaxBands = (name: string, selected: SelectOption) => {
    setTaxBandsPlacement((prev) => ({ ...prev, [name]: selected.value }));
    setSelectedTaxBands({ id: Number(selected.value), label: selected.label });
  };

  const updateDate = (name: string, date: string) => {
    setTaxBandsPlacement((prev) => ({
      ...prev,
      [name]: date ? ToApiConverter.convertDate(date) : ''
    }));
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      await service.addTaxBandsPlacement(taxBandsPlacement);

      setTaxBandsPlacement(defaultTaxBandsPlacement);
      setSelectedTaxBands(null);

      await Promise.all([
        fetchTaxBandsList(),
        getPlacementsForTaxBands(taxBandsPlacement.taxBandsId),
        selectTaxBands({ id: taxBandsPlacement.taxBandsId })
      ]);
    } catch (response) {
      setError(new ResponseError(response));
    } finally {
      setLoading(false);
    }
  };

  return {
    actions: { onSubmit, updateDate, updateQuantity, updateSelect, updateSelectedTaxBands },
    state: { loading, error, selectedTaxBands, taxBandsPlacement }
  };
};
