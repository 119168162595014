import { useEffect } from 'react';

export const useAutosizeTextArea = (
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  value: string
) => {
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = '0px'; // Reset wysokości
      textArea.style.height = `${textArea.scrollHeight}px`; // Ustawienie nowej wysokości
    }
  }, [textAreaRef, value]);
};
