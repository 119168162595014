import URI from 'urijs';
import { StringValueValidator } from '../services/Validators';
import useTransformResponse from '../hooks/useTransformResponse';
import { usePushHistory } from '../hooks/usePushHistory';

const useFetch = <T>(endpoint?: string) => {
  const defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const { pushHistory } = usePushHistory();

  const customFetch = (
    url,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body,
    headers = defaultHeader
  ) => {
    const options = {
      method,
      headers,
      body
    };
    if (StringValueValidator.isNotBlank(body)) {
      options.body = JSON.stringify(body);
    }
    return fetch(url, options)
      .then(useTransformResponse)
      .catch((e) => {
        if (e instanceof SyntaxError) {
          pushHistory('/mv/error/404');
        } else {
          throw e;
        }
      });
  };
  const getAllByParams = (
    paramName1: string,
    paramValue1: number | string,
    paramName2?: string,
    paramValue2?: number | string
  ) => {
    const url = `${endpoint}?${URI.buildQuery({ catalogId: paramValue1, typeId: paramValue2 })}`;
    return customFetch(url, 'GET', null);
  };
  const get = (id?: number) => {
    const url = id ? `${endpoint}/${id}` : endpoint;
    return customFetch(url, 'GET', null).catch((e) => {
      if (e.status === 404) {
        pushHistory('/mv/error/404');
      } else {
        throw e;
      }
    });
  };
  const getList = () => {
    const url = `${endpoint}/all`;
    return customFetch(url, 'GET', null);
  };
  const getAll = (page: number, status?: string) => {
    status = status ? status.toUpperCase() : status;
    const url = `${endpoint}?${URI.buildQuery({ p: page, s: status })}`;
    return customFetch(url, 'GET', null);
  };
  const getAllWithParams = (params: Map<string, string | boolean>) => {
    const url = `${endpoint}/all?${URI.buildQuery(Object.fromEntries(params.entries()))}`;
    return customFetch(url, 'GET', null);
  };
  const post = (body: T, params?: Map<string, string | boolean>) => {
    const url = params
      ? `${endpoint}?${URI.buildQuery(Object.fromEntries(params.entries()))}`
      : endpoint;
    return customFetch(url, 'POST', body);
  };

  const postFile = (files, fileEndpoint: string) => {
    return fetch(fileEndpoint, {
      method: 'POST',
      body: files,
      credentials: 'include'
    }).then(useTransformResponse);
  };

  const putFile = (files, fileEndpoint: string) => {
    return customFetch(fileEndpoint, 'PUT', files);
  };

  const put = (id: number, body: T) => {
    const url = `${endpoint}/${id}`;
    return customFetch(url, 'PUT', body);
  };
  const putBody = (body: T) => {
    const url = `${endpoint}`;
    return customFetch(url, 'PUT', body);
  };
  const putId = (id: number) => {
    const url = `${endpoint}/${id}`;
    return customFetch(url, 'PUT', null);
  };
  const del = (id?: number, page?: number) => {
    const url = id ? `${endpoint}/${id}?${URI.buildQuery({ p: page })}` : `${endpoint}`;
    return customFetch(url, 'DELETE', null);
  };

  const delByTwoIds = (id: number, secondId: number) => {
    const url = `${endpoint}/${id}/${secondId}`;
    return customFetch(url, 'DELETE', null);
  };

  return {
    get,
    getList,
    getAll,
    getAllWithParams,
    post,
    put,
    putBody,
    putId,
    del,
    delByTwoIds,
    getAllByParams,
    postFile,
    putFile
  };
};
export default useFetch;
