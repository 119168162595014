import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { TaxBandsReadModel } from '../../../../../types/TaxBands';
import { PlacementsForTaxBandsProps } from '../hooks/usePlacementsForTaxBands';
import { FormErrorMessage } from '../../../../../components/notifications/FormErrorMessage';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import useMapPagination from '../../../../../hooks/useMapPagination';
import { TaxBandsPlacementListPresentationData } from '../../../../winery/components/wine_storage/types/WineStorage';
import { PlacementsRow, RemovePlacementProps } from './PlacementsRow';
import { PaginationComponent } from '../../../../../components/PaginationComponent';
import { Pagination } from '../../../../../types/Pagination';
import { useMapSearch } from '../../../../../hooks/useMapSearch';
import { SearchComponent } from '../../../../../components/common/search/SearchComponent';
import { useConfirmation } from '../../../../notifications/useConfirmation';

const Header = ({
  selectedTaxBands,
  search,
  removeTaxBands,
  showRemoveBtn
}: {
  selectedTaxBands: TaxBandsReadModel;
  search: (value: string) => void;
  removeTaxBands: (id: number) => void;
  showRemoveBtn: boolean;
}) => {
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const removeTaxBandsHandler = (id: number) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_TAX_BANDS'),
      actionLabel: 'Usuń',
      actionCallback: () => removeTaxBands(id)
    });
  };
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="float-left">
        {showRemoveBtn ? (
          <button
            className={'ml-2 btn btn-sm btn-danger'}
            onClick={() => removeTaxBandsHandler(selectedTaxBands.id)}
          >
            <em className="fa fa-trash fa-fw" />
            {t('taxBands.board.delete')}
          </button>
        ) : (
          <SearchComponent search={search} />
        )}
      </div>
      <div className="mb-2"></div>
      <small className="text-muted float right">
        {t('taxBands.board.selected')} {selectedTaxBands?.label}
      </small>
    </div>
  );
};

const TableSection = ({ children }: { children: React.ReactNode }) => (
  <div className="dataTables_wrapper dt-bootstrap4 no-footer">
    <div className="row">
      <div className="col-sm-12 table-responsive">
        <table className="table w-100 no-footer table-sm" style={{ width: 1068 }}>
          {children}
        </table>
      </div>
    </div>
  </div>
);

const THead = ({ short, shorter }: { short: boolean; shorter: boolean }) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr role="row">
        <th style={{ width: 85 }}>{t('placements.table.placementDate')}</th>
        <th className={'text-center'} style={{ width: 85 }}>
          {t('placements.table.quantity')}
        </th>
        <th className={'text-center'} style={{ width: 30 }}>
          {t('placements.table.wineStorage.name')}
        </th>
        {!shorter ? (
          <th className={'text-center'} style={{ width: 85 }}>
            {t('placements.table.placementResult')}
          </th>
        ) : null}
        {!short ? (
          <>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('placements.table.declarationDate')}
            </th>
            <th className={'text-center'} style={{ width: 85 }}>
              {t('placements.table.payed')}
            </th>
          </>
        ) : null}
        <th className={'text-center'} style={{ width: 20 }}>
          {t('common.ACTIONS')}
        </th>
      </tr>
    </thead>
  );
};

const TBody = ({
  short,
  shorter,
  pagination,
  removePlacement,
  disabledRemove
}: {
  short: boolean;
  shorter: boolean;
  pagination: Pagination<Map<string, TaxBandsPlacementListPresentationData[]>>;
} & RemovePlacementProps) => {
  return (
    <tbody>
      {Array.from(pagination.getCurrentResults().entries()).map(([date, values], index) => (
        <PlacementsRow
          placements={values}
          short={short}
          shorter={shorter}
          date={date}
          key={date}
          withBackground={index % 2 === 0}
          removePlacement={removePlacement}
          disabledRemove={disabledRemove}
        />
      ))}
      {pagination.getCurrentResults().size === 0 ? (
        <tr>
          <td colSpan={100} className="text-center">
            Brak danych
          </td>
        </tr>
      ) : null}
    </tbody>
  );
};

export const TaxBandsSelected: React.FC<
  {
    selectedTaxBands: TaxBandsReadModel;
    removeTaxBands: (id: number) => void;
  } & PlacementsForTaxBandsProps &
    RemovePlacementProps
> = ({
  selectedTaxBands,
  loading,
  placements,
  error,
  removeTaxBands,
  removePlacement,
  disabledRemove
}) => {
  const { isMobile, isSmallMobile } = useResponsiveContext();
  const mapData = useMemo(() => new Map(Object.entries(placements || {})), [placements]);
  const [result, search] = useMapSearch<string, TaxBandsPlacementListPresentationData>({
    entities: mapData,
    field: [
      'placementDate',
      'declarationDate',
      'wineStorage',
      'placementResult',
      'quantity',
      'payed'
    ]
  });
  const pagination = useMapPagination<string, TaxBandsPlacementListPresentationData[]>(result, 10);

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header
            selectedTaxBands={selectedTaxBands}
            search={search}
            removeTaxBands={removeTaxBands}
            showRemoveBtn={!loading && mapData.size === 0}
          />
          <TableSection>
            <THead short={isMobile} shorter={isSmallMobile} />
            {placements ? (
              <TBody
                short={isMobile}
                shorter={isSmallMobile}
                pagination={pagination}
                removePlacement={removePlacement}
                disabledRemove={disabledRemove}
              />
            ) : null}
          </TableSection>
          <FormErrorMessage
            error={error}
            messageType={'details'}
            clazzName={'p-1'}
            key={selectedTaxBands.id}
          />
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
