import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../../../../../../types/SelectOption';
import { ServiceError } from '../../../../../../types/Service';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Bottle } from '../../../wine_storage/types/WineStorage';
import useBottlesForBottlingReceiver from '../../../wine_storage/hooks/useBottlesForBottlingReceiver';

interface Props {
  value: Bottle | SelectOption | number;
  onChange: (s: string | SelectOption) => void;
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  error?: ServiceError;
  numberOfColumnsInRow?: NumberOdColumnsInRow;
  hideLink?: boolean;
}

const SelectBottlesForBottling: React.FC<Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  disabled,
  error,
  hideLink,
  numberOfColumnsInRow = 1
}) => {
  const { t } = useTranslation();
  const { selected: value, options: bottles } = useBottlesForBottlingReceiver(selected);
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: '#d92550',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right calc(2.25rem / 4)',
      backgroundSize: 'calc(2.25rem / 2) calc(2.25rem / 2)'
    })
  };
  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <Select
        name={name}
        options={bottles}
        onChange={onChange}
        value={value}
        placeholder={'Wybierz'}
        styles={error?.hasError?.(name) && customStyles}
        isDisabled={disabled}
        isOptionDisabled={(option) => {
          if (typeof option !== 'string') {
            return option.lock;
          }
          return false;
        }}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
      {hideLink ? null : (
        <small className={'pl-1'}>
          <Link
            to={{
              pathname: '/mv/board/bottles',
              state: { from: window.location.hash?.split('#')?.[1] }
            }}
          >
            {t('bottles.form.submit')}
          </Link>
        </small>
      )}
    </div>
  );
};

export default SelectBottlesForBottling;
