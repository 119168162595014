import React, { FC, useContext } from 'react';
import { Card, CardBody, CardFooter, Table } from 'reactstrap';
import { WineStoragePresentationData } from '../../../../../../types/dto';
import { WineInfoButtons } from '../../../wine/components/WineInfo/WineInfoContainer';
import { ServiceError } from '../../../../../../types/Service';
import { useTranslation } from 'react-i18next';
import SmallTextBadge from '../../../../../../components/common/badges/SmallTextBadge';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import ProgressBar from '../../../../../../components/common/charts/ProgressBar';
import { PdfPageLayout } from '../../../../../../types/enums';
import log from 'loglevel';
import { AuthContext } from '../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { LockIconInfo } from '../../../../../../components/common/lock/LockIconInfo';
import { LockArea } from '../../../../../../components/common/lock/LockArea';
import ListActions from '../../../../../../components/common/ListActions';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const WineStorageInfoBox: FC<{
  buttons: WineInfoButtons;
  wine: WineStoragePresentationData;
  loading: boolean;
  error?: ServiceError;
}> = ({ buttons: { saveEditButton, archiveButton, removeButton }, wine, loading, error }) => {
  const { t } = useTranslation();

  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby pobrać ten raport',
    itemIndexToHighlight: 6
  });

  const { isValidSubscription: isValidSubscriptionForArchive } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby zarchiwizować to wino',
    itemIndexToHighlight: 1
  });

  function handleArchive() {
    if (!isValidSubscriptionForArchive()) {
      return;
    }
    archiveButton?.action();
  }

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/WINE_IN_STORAGE?layout=${layout}&id=${wine.id}`;
  }

  const actions = {
    proceed: saveEditButton.action,
    archive: handleArchive,
    revertArchive: archiveButton.action,
    remove: removeButton.action
  };

  function getCategoryOfWine(wine: WineStoragePresentationData) {
    if (wine.varietalWine) return 'Wino odmianowe';
    if (wine.protectedNameOrGeoWine) return 'Wino z ChNP lub ChOG';
    return 'Pozostałe';
  }

  return (
    <SectionWrapper loading={loading}>
      <Card>
        <CardBody>
          {wine?.wineTaste && (
            <SmallTextBadge
              title={t(`wine.TASTE.${wine.wineTaste}`)}
              color={t(`wine_info_box.badge.color.${wine.wineTaste}`)}
            />
          )}
          {wine?.wineColor && (
            <SmallTextBadge
              title={t(`wine.COLOR.${wine.wineColor}`)}
              color={t(`wine_info_box.badge.color.${wine.wineColor}`)}
            />
          )}
          <h4 className="m-0">{wine?.name}</h4>
          <CardBody>
            <ProgressBar
              percent={wine.totalLiters ? Math.round((wine.totalLiters / wine.maxLiters) * 100) : 0}
              color={'warning'}
              title={'Pozostało litrów'}
            />
          </CardBody>
          <Table>
            <tbody>
              {wine.taxBandsPlacementNeedAttention && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'UWAGA'}
                  value={'Rozlicz banderole!'}
                  valueColumnClassName={'text-right text-warning'}
                />
              ) : null}
              <CommonRow
                label={'Litrów'}
                value={wine.totalLiters + ' l.'}
                valueColumnClassName={'text-right'}
              />
              {wine.totalBottles && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Butelek'}
                  value={wine.totalBottles + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.litersInBottles && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Litrów w butelkach'}
                  value={wine.litersInBottles + ' l.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.totalTanks && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Zbiorników'}
                  value={wine.totalTanks + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.litersInTanks > 0 && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Litrów w zbiornikach'}
                  value={wine.litersInTanks + ' l.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.taxBandsTotalQuantity && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Naklejonych akcyz'}
                  value={wine.taxBandsTotalQuantity + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.taxBandsTotalQuantity && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Pozostało z akcyzą'}
                  value={wine.taxBandsActualQuantity + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.totalNotFinishedPlacementsOfTaxBands && accessInfo.hasPremiumAccess ? (
                <CommonRow
                  label={'Banderole do rozliczenia'}
                  value={wine.totalNotFinishedPlacementsOfTaxBands + ' szt.'}
                  valueColumnClassName={'text-right text-warning'}
                />
              ) : null}
              <CommonRow
                label={'Kategoria'}
                value={getCategoryOfWine(wine)}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={'Wino z UE'}
                value={wine.fromUE ? 'Tak' : 'Nie'}
                valueColumnClassName={'text-right'}
              />
            </tbody>
          </Table>
          <LockArea
            subscriptionTypeNeeded={SubscriptionType.PREMIUM}
            blockingMessage={'Przejdź na wyższy plan, aby wyświetlić więcej danych'}
            label={'Odblokuj więcej statystyk'}
            itemIndexToHighlight={1}
          />
          <CardFooter className="text-center bg-secondary">
            <div>
              <div className="float-left">
                <button
                  className="btn btn-sm btn-secondary float-right"
                  type="button"
                  onClick={() => getPdf()}
                  title={t('button.download.pdf')}
                >
                  <em className="ml-auto fa fa-download fa-fw text-info"></em>
                  <span className={'ml-1'}>{t('button.download.report')}</span>
                  <LockIconInfo
                    accessInfo={accessInfo}
                    subscriptionTypeNeeded={SubscriptionType.PREMIUM}
                  />
                </button>
              </div>
              <div className="mr-1 float-right">
                <ListActions
                  entity={wine}
                  actions={actions}
                  subscriptionTypeToArchive={SubscriptionType.PREMIUM}
                />
              </div>
            </div>
          </CardFooter>
        </CardBody>
        <div className="px-4 text-center bg-secondary">
          {error && <FormErrorMessage error={error} messageType={'details'} />}
        </div>
      </Card>
    </SectionWrapper>
  );
};

export default WineStorageInfoBox;
