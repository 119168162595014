import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormErrorMessage } from '../../../../../components/notifications/FormErrorMessage';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import { InputColumn } from '../../../../../components/forms/InputColumn';
import { DatePickerColumn } from '../../../../../components/forms/DatePickerColumn';
import { ServiceError } from '../../../../../types/Service';
import { SelectOption } from '../../../../../types/SelectOption';
import { PlacementForm } from '../../../../winery/components/wine_storage/types/WineStorage';
import SelectTaxBands from '../../../../winery/components/wine/components/WineForm/SelectTaxBands';
import SelectPlacementResult from '../../../../winery/common/select/SelectPlacementResult';

interface Props {
  taxBandsPlacement: PlacementForm;
  updateDate: (name: string, date: string) => void;
  updateQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: ServiceError;
  onSubmit: () => void;
  updateSelect: (name: string, value: SelectOption) => void;
  updateSelectedTaxBands: (name: string, selected: SelectOption) => void;
  selectedTaxBands: SelectOption;
}

export const AddPlacementPresentation: React.FC<Props> = ({
  taxBandsPlacement,
  updateDate,
  updateQuantity,
  error,
  onSubmit,
  updateSelectedTaxBands,
  selectedTaxBands,
  updateSelect
}) => {
  const { t } = useTranslation();
  const { taxBandsPlacementId } = useParams<RouterParams>();

  return (
    <div className="form-group row">
      <div className="col-md-12">
        <div className="form-row">
          <SelectTaxBands
            value={selectedTaxBands}
            name={'taxBandsId'}
            label={t('wineStorage.taxBandsPlacement.TAX_BANDS_ID')}
            onChange={updateSelectedTaxBands}
            disabled={!!taxBandsPlacementId}
            error={error}
            numberOfColumnsInRow={4}
            hideLink={true}
          />
          <InputColumn
            name={'quantity'}
            onChange={updateQuantity}
            type={'number'}
            error={error}
            label={t('wineStorage.taxBandsPlacement.QUANTITY')}
            placeholder={t('wineStorage.taxBandsPlacement.QUANTITY')}
            disabled={!!taxBandsPlacementId}
            value={taxBandsPlacement?.quantity}
            numberOfColumnsInRow={4}
          />
          <DatePickerColumn
            name={'placementDate'}
            value={taxBandsPlacement?.placementDate}
            onChange={updateDate}
            error={error}
            label={t('wineStorage.taxBandsPlacement.PLACEMENT_DATE')}
            disabled={!!taxBandsPlacementId}
            numberOfColumnsInRow={4}
          />
          <SelectPlacementResult
            value={taxBandsPlacement?.placementResult}
            name={'placementResult'}
            label={t('wineStorage.taxBandsPlacement.PLACEMENT_RESULT')}
            onChange={updateSelect}
            error={error}
            numberOfColumnsInRow={4}
          />
        </div>
        <FormErrorMessage error={error} messageType={'details'} clazzName={'p-1'} />
        <button
          className="btn btn-primary"
          type="button"
          onClick={onSubmit}
          disabled={!taxBandsPlacement?.placementDate || !taxBandsPlacement?.quantity}
        >
          {t('taxBands.form.placement.submit')}
        </button>
      </div>
    </div>
  );
};
