import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../platform/AuthContext';
import { SubscriptionType } from '../../../../../../types/Subscription';
import usePlanPicker from '../../../../../subscription/hooks/usePlanPicker';
import { useResponsiveContext } from '../../../../../platform/ResponsiveContext';
import { LockIconInfo } from '../../../../../../components/common/lock/LockIconInfo';
import { ReportType, useGetPdf } from '../../../../hooks/useGetPdf';
import { BottlesTab } from '../../hooks/useBottles';

const TabButton: React.FC<{
  tab: BottlesTab;
  label: string;
  activeTab: BottlesTab;
  onClick: ({ newTab }: { newTab: BottlesTab }) => void;
}> = ({ tab, label, activeTab, onClick }) => {
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby zarządzać butelkami',
    itemIndexToHighlight: 2
  });

  function handleClickButton() {
    if (!isValidSubscription()) {
      return;
    }
    onClick({ newTab: tab });
  }

  return (
    <button
      className={`mr-2 btn btn-sm ${activeTab !== tab ? 'btn-info' : 'btn-danger'}`}
      type="button"
      onClick={handleClickButton}
    >
      {activeTab === tab ? (
        <>
          <em className="mr-1 fas fa-minus" /> {label}
        </>
      ) : (
        <>
          <em className="mr-1 fas fa-plus" /> {label}
        </>
      )}
      <LockIconInfo
        accessInfo={accessInfo}
        subscriptionTypeNeeded={SubscriptionType.PREMIUM}
        hideLabel
      />
    </button>
  );
};

export const ReportButton: React.FC<{
  reportAvailable: boolean;
  getPdf: () => void;
}> = ({ reportAvailable, getPdf }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  if (!reportAvailable) return null;

  return (
    <button
      className="mr-2 btn btn-sm btn-secondary ml-1 float-right"
      type="button"
      onClick={() => getPdf()}
      title={t('button.download.pdf')}
    >
      <em className="ml-auto fa fa-download fa-fw text-info"></em>
      {!isMobile ? <span className="ml-1">{t('button.download.report')}</span> : null}
      <LockIconInfo accessInfo={accessInfo} subscriptionTypeNeeded={SubscriptionType.PREMIUM} />
    </button>
  );
};

export const BottlesListButtonsBar: React.FC<{
  reportAvailable: boolean;
  onClick: ({ newTab }: { newTab: BottlesTab }) => void;
  activeTab: BottlesTab;
}> = ({ reportAvailable, onClick, activeTab }) => {
  const { t } = useTranslation();
  const { getPdf } = useGetPdf({
    subscriptionType: SubscriptionType.PREMIUM,
    reportType: ReportType.BOTTLES_SUMMARY
  });

  return (
    <div className="mb-3">
      <div>
        <TabButton
          tab="form"
          label={t('bottles.page.tab.addBottle')}
          activeTab={activeTab}
          onClick={onClick}
        />
        <ReportButton reportAvailable={reportAvailable} getPdf={getPdf} />
      </div>
    </div>
  );
};
