import { useTranslation } from 'react-i18next';
import usePlanPicker from '../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../types/Subscription';
import { PdfPageLayout } from '../../../types/enums';
import log from 'loglevel';

export enum ReportType {
  VINEYARD_SUMMARY = 'VINEYARD_SUMMARY',
  WINERY_SUMMARY = 'WINERY_SUMMARY',
  STORAGE_SUMMARY = 'STORAGE_SUMMARY',
  STORAGE_SUMMARY_BY_GROUP = 'STORAGE_SUMMARY_BY_GROUP',
  STORAGE_SUMMARY_2 = 'STORAGE_SUMMARY_2',
  STORAGE_SUMMARY_3 = 'STORAGE_SUMMARY_3',
  PARCELS_SUMMARY = 'PARCELS_SUMMARY',
  GRAPEVINE_SUMMARY = 'GRAPEVINE_SUMMARY',
  TANKS_SUMMARY = 'TANKS_SUMMARY',
  INGREDIENTS_SUMMARY = 'INGREDIENTS_SUMMARY',
  TANKS_WINERY = 'TANKS_WINERY',
  TANKS_STORAGE = 'TANKS_STORAGE',
  CURRENT_HARVESTS = 'CURRENT_HARVESTS',
  HARVEST_SUMMARY = 'HARVEST_SUMMARY',
  CURRENT_WINES = 'CURRENT_WINES',
  STORAGE_WINES = 'STORAGE_WINES',
  STORAGE_VARIETAL_WINES = 'STORAGE_VARIETAL_WINES',
  STORAGE_PROTECTED_WINES = 'STORAGE_PROTECTED_WINES',
  STORAGE_OTHER_WINES = 'STORAGE_OTHER_WINES',
  WINES_IN_HARVEST = 'WINES_IN_HARVEST',
  WINES_IN_SEASON = 'WINES_IN_SEASON',
  KOWR_JANUARY_15_1 = 'KOWR_JANUARY_15_1',
  KOWR_JANUARY_15_2 = 'KOWR_JANUARY_15_2',
  KOWR_SEPTEMBER_31 = 'KOWR_SEPTEMBER_31',
  WINE_IN_STORAGE = 'WINE_IN_STORAGE',
  WINE = 'WINE',
  DISPOSED_AS_WINE_ENTRY = 'DISPOSED_AS_WINE_ENTRY',
  WINE_ENTRIES = 'WINE_ENTRIES',
  WINE_PRODUCTION = 'WINE_PRODUCTION',
  APPLIED_INGREDIENTS = 'APPLIED_INGREDIENTS',
  PRODUCTION_EVENTS = 'PRODUCTION_EVENTS',
  HISTORY = 'HISTORY',
  TAX_BANDS_SUMMARY = 'TAX_BANDS_SUMMARY',
  TAX_BANDS = 'TAX_BANDS',
  TAX_BANDS_PLACEMENTS = 'TAX_BANDS_PLACEMENTS',
  BOTTLE_OUTPUTS = 'BOTTLE_OUTPUTS',
  HARVEST = 'HARVEST',
  BOTTLES_SUMMARY = 'BOTTLES_SUMMARY',
  BOTTLE = 'BOTTLE'
}

export function useGetPdf({
  subscriptionType,
  reportType,
  id
}: {
  subscriptionType: SubscriptionType;
  reportType: ReportType;
  id?: number;
}): { getPdf: (layout?: PdfPageLayout) => void } {
  const { t } = useTranslation();
  const { isValidSubscription } = usePlanPicker({
    subscriptionType: subscriptionType,
    fromBlockingContent: true,
    blockingMessage: t('subscriptions.pdf.warning')
  });

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF', reportType, layout);
    const url = `/ajax/pdf/${reportType}?layout=${layout}`;
    window.location.href = id ? url + `&id=${id}` : url;
  }

  return { getPdf };
}
