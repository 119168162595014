import React from 'react';
import { useTranslation } from 'react-i18next';
import { BottlesRow } from './BottlesRow';
import { useResponsiveContext } from '../../../../../platform/ResponsiveContext';
import { SearchComponent } from '../../../../../../components/common/search/SearchComponent';
import { Pagination } from '../../../../../../types/Pagination';
import { Bottle } from '../../../../../winery/components/wine_storage/types/WineStorage';
import { useSearch } from '../../../../../../hooks/useSearch';
import usePagination from '../../../../../../hooks/usePagination';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { PaginationComponent } from '../../../../../../components/PaginationComponent';

const Header = ({
  search,
  showArchivedBottles,
  toggleShowArchivedBottles
}: {
  search: (value: string) => void;
  showArchivedBottles: boolean;
  toggleShowArchivedBottles: () => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();

  function getMessage() {
    if (isMobile) return '';
    return showArchivedBottles
      ? t('bottles.board.show.available.btn')
      : t('bottles.board.show.archived.btn');
  }

  return (
    <>
      <small className="text-muted float-right">{t('bottles.board.list')}</small>
      <div className="float-left">
        <SearchComponent search={search} />
        <button
          title={
            showArchivedBottles
              ? t('taxBands.board.show.available')
              : t('taxBands.board.show.inAvailable')
          }
          className={'ml-2 btn btn-sm ' + (showArchivedBottles ? 'btn-info' : 'btn-outline-info')}
          onClick={toggleShowArchivedBottles}
        >
          <em className="fa-1x fas fa-archive mr-1"></em> {isMobile ? null : getMessage()}
        </button>
      </div>
    </>
  );
};

const TableSection = ({ children }: { children: React.ReactNode }) => (
  <div className="dataTables_wrapper dt-bootstrap4 no-footer">
    <div className="row">
      <div className="col-sm-12 table-responsive">
        <table className="table w-100 no-footer table-hover table-sm" style={{ width: 1068 }}>
          {children}
        </table>
      </div>
    </div>
  </div>
);

const THead = ({ short }: { short: boolean }) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr role="row">
        <th style={{ minWidth: '150px' }}>{t('bottles.table.name')}</th>
        <th className={'text-center'}>{t('bottles.table.capacity')}</th>
        <th className={'text-center'}>{t('bottles.table.forBottling')}</th>
        {!short ? (
          <th className={'text-center'}>{t('bottles.table.approximateQuantity')}</th>
        ) : null}
      </tr>
    </thead>
  );
};

const TBody = ({
  short,
  pagination,
  selectBottle,
  selectedBottle
}: {
  short: boolean;
  pagination: Pagination<Bottle[]>;
  selectBottle: ({ id }: { id: number }) => void;
  selectedBottle: Bottle;
}) => (
  <tbody>
    {pagination.getCurrentResults().map((bottle) => {
      return (
        <BottlesRow
          bottle={bottle}
          onClick={() => selectBottle({ id: bottle.id })}
          key={bottle.id}
          short={short}
          selectedBottle={selectedBottle}
        />
      );
    })}
    {pagination.getCurrentResults().length === 0 ? (
      <tr>
        <td colSpan={100} className="text-center">
          Brak danych
        </td>
      </tr>
    ) : null}
  </tbody>
);

export const BottlesListTable: React.FC<{
  bottles: Bottle[];
  loading: boolean;
  showArchivedBottles: boolean;
  toggleShowArchivedBottles: () => void;
  selectBottle: ({ id }: { id: number }) => void;
  selectedBottle: Bottle;
}> = ({
  bottles,
  loading,
  showArchivedBottles,
  toggleShowArchivedBottles,
  selectBottle,
  selectedBottle
}) => {
  const { isMobile } = useResponsiveContext();

  const [result, search] = useSearch<Bottle>({
    entities: bottles,
    field: ['name', 'capacity', 'info', 'approximateQuantity', 'forBottling']
  });
  const pagination = usePagination(result, 5);
  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header
            search={search}
            showArchivedBottles={showArchivedBottles}
            toggleShowArchivedBottles={toggleShowArchivedBottles}
          />
          <TableSection>
            <THead short={isMobile} />
            <TBody
              short={isMobile}
              pagination={pagination}
              selectBottle={selectBottle}
              selectedBottle={selectedBottle}
            />
          </TableSection>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
