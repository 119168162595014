import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import SelectIngredientType from '../../IngredientForm/SelectIngredientType';
import SelectIngredient from './SelectIngredient';
import { ServiceError } from '../../../../../../../types/Service';
import { Ingredient } from '../../../types/Ingredient';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import { DatePickerColumn } from '../../../../../../../components/forms/DatePickerColumn';
import { InputColumn } from '../../../../../../../components/forms/InputColumn';
import { TextareaColumn } from '../../../../../../../components/forms/TextareaColumn';

interface Props {
  ingredient: Ingredient;
  actions: {
    updateIngredientSelect: (e: ChangeEvent<HTMLInputElement>) => void;
    updateTypeSelect: (name: string, s: Record<string, string>) => void;
    onChange: () => void;
    updateDate: () => void;
  };
  error: ServiceError;
  editing?: boolean;
}

export const AddIngredientFormFields: FC<Props> = ({
  ingredient,
  actions: { updateIngredientSelect, updateTypeSelect, onChange, updateDate },
  error,
  editing
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SelectIngredientType
        value={ingredient.type}
        name={'type'}
        label={t('add_ingredient.SELECT_TYPE')}
        onChange={updateTypeSelect}
        error={error}
        optional
        disabled={editing}
      />
      <SelectIngredient
        value={ingredient.ingredient}
        type={ingredient.type}
        name={'ingredient'}
        label={t('add_ingredient.SELECT_INGREDIENT')}
        onChange={updateIngredientSelect}
        error={error}
        disabled={editing}
      />
      <DatePickerColumn
        label={t('add_ingredient.APPLIED_DATE')}
        name={'appliedDate'}
        value={ingredient.appliedDate}
        onChange={updateDate}
        error={error}
        showTimeSelect
      />
      <InputColumn
        label={t('add_ingredient.AMOUNT')}
        type={'number'}
        name={'amount'}
        onChange={onChange}
        value={ingredient.amount}
        error={error}
      />
      <TextareaColumn
        label={t('add_ingredient.NOTES')}
        name={'notes'}
        onChange={onChange}
        value={ingredient.notes}
        error={error}
        optional
      />
      <FormErrorMessage error={error} />
    </>
  );
};
