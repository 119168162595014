import React from 'react';
import { SelectOption } from '../../../../types/SelectOption';
import { ServiceError } from '../../../../types/Service';
import {
  PlacementResult,
  PlacementResultForSelect
} from '../../components/wine_storage/types/WineStorage';
import usePlacementResultReceiver from '../../../home/components/TaxBandsSummaryPage/hooks/usePlacementResultReceiver';
import { SelectTypeColumn } from '../../../../components/forms/SelectTypeColumn';

interface Props {
  value?: PlacementResultForSelect | PlacementResult | SelectOption | null;
  onChange: (name: string, s: Record<string, string>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  numberOfColumnsInRow?: 1 | 2 | 3 | 4 | 6;
}

const SelectWineColor: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled,
  numberOfColumnsInRow
}) => {
  return (
    <SelectTypeColumn
      onChange={onChange}
      name={name}
      receiver={usePlacementResultReceiver}
      error={error}
      optional={optional}
      label={label}
      value={value}
      disabled={disabled}
      numberOfColumnsInRow={numberOfColumnsInRow}
    />
  );
};

export default SelectWineColor;
