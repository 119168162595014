import React from 'react';
import { TaxBandsPlacementListPresentationData } from '../../../../winery/components/wine_storage/types/WineStorage';
import { useTranslation } from 'react-i18next';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import '../assets/table.css';
import { Button } from '../../../../../components/common/buttons/Button';

type IProps = {
  placements: TaxBandsPlacementListPresentationData[];
  short: boolean;
  shorter: boolean;
  date: string;
  withBackground: boolean;
} & RemovePlacementProps;

export type RemovePlacementProps = {
  removePlacement: ({
    placementId,
    wineStorageId
  }: {
    placementId: number;
    wineStorageId: number;
  }) => void;
  disabledRemove: boolean;
};

export const PlacementsRow: React.FC<IProps> = ({
  date,
  placements,
  short,
  shorter,
  withBackground,
  removePlacement,
  disabledRemove
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function navigateToWine(id: number) {
    if (!id) return;
    pushHistory(`/mv/wine_storage/info/${id}`);
  }

  function navigateToTaxBandPlacement(placementValue: TaxBandsPlacementListPresentationData) {
    pushHistory(
      `/mv/wine_storage/tax_band_placement/${placementValue.wineStorage.id}/${placementValue.id}/`,
      `/mv/board/tax_bands/${placementValue.taxBandsId}`
    );
  }

  return (
    <>
      {placements.map((placement, index) => (
        <tr key={placement.id} className={withBackground ? 'bg-light' : ''}>
          {index === 0 ? (
            <td className="text-bold" rowSpan={placements.length}>
              {date}
            </td>
          ) : null}
          <td className={'text-center'}>{placement.quantity}</td>
          <td
            className={'text-center btn-link'}
            style={{ cursor: placement.wineStorage?.id ? 'pointer' : '' }}
            onClick={() => navigateToWine(placement.wineStorage?.id)}
          >
            {placement.wineStorage?.name}
          </td>
          {!shorter ? (
            <td className={'text-center'}>
              {t(`placement.placementResult.${placement.placementResult}`)}
            </td>
          ) : null}
          {!short ? (
            <>
              <td className={'text-center'}>{placement.declarationDate}</td>
              <td className={'text-center ' + (placement.payed ? 'text-success' : 'text-danger')}>
                {placement.payed ? t('answer.yes') : t('answer.no')}
              </td>
            </>
          ) : null}
          <td className={'text-center text-nowrap'}>
            <Button.Edit onClick={() => navigateToTaxBandPlacement(placement)} />
            <Button.Delete
              onClick={() =>
                removePlacement({
                  placementId: placement.id,
                  wineStorageId: placement.wineStorage?.id || 0
                })
              }
              disabled={disabledRemove}
            />
          </td>
        </tr>
      ))}
    </>
  );
};
