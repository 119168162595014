import useCrudeApi from '../../../../../lib/useCrudeApi';
import { Bottle } from '../../../../winery/components/wine_storage/types/WineStorage';
import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';

export function useBottleService() {
  const { getList: getListForBottling, putId: toggleForBottling } =
    useCrudeApi<Bottle>(`/ajax/bottles/bottling`);
  const {
    getAllWithParams,
    post: addBottle,
    del,
    put: updateBottle,
    get
  } = useCrudeApi<Bottle>(`/ajax/bottles`);
  const { putId: archive } = useCrudeApi<Bottle>('/ajax/bottles/archive');
  const { getAllWithParams: getWineStorages } = useCrudeApi<Bottle>('/ajax/bottles/wines');
  const { putId: revertArchive } = useCrudeApi<Bottle>('/ajax/bottles/revert-archive');

  function getListByStatus({ status }: { status: EntityLiveStatus }) {
    return getAllWithParams(new Map<string, EntityLiveStatus>([['s', status]]));
  }

  function getWineStoragesInBottle({ bottleId }: { bottleId: number }) {
    return getWineStorages(new Map<string, number>([['id', bottleId]]));
  }

  return {
    get,
    getListForBottling,
    getListByStatus,
    addBottle,
    del,
    archive,
    revertArchive,
    updateBottle,
    toggleForBottling,
    getWineStoragesInBottle
  };
}
