import { useCallback, useEffect, useState } from 'react';
import { TaxBandsInfo, TaxBandsReadModel } from '../../../../../types/TaxBands';
import { useToggle } from '../../../../../hooks/useToggle';
import { ServiceError } from '../../../../../types/Service';
import { useTaxBandsService } from './useTaxBandsService';
import { ResponseError } from '../../../../error/ResponseError';
import log from 'loglevel';
import { useSelectedTaxBands } from './useSelectedTaxBands';

export type TaxBandsTab = 'list' | 'form' | 'addPlacement';

interface ReturnType {
  actions: {
    addTaxBands: (taxBands: TaxBandsInfo) => void;
    toggleShowAllTaxBands: () => void;
    handleChangeTab: ({ newTab }: { newTab: TaxBandsTab }) => void;
    toggleShowAllFields: () => void;
    removeTaxBands: (id: number) => void;
    selectTaxBands: ({ id }: { id: number }) => void;
    getList: () => void;
  };
  state: {
    taxBands: TaxBandsReadModel[];
    showAllTaxBands: boolean;
    showAllFields: boolean;
    tab: TaxBandsTab;
    loading: boolean;
    error: ServiceError;
    selectedTaxBands: TaxBandsReadModel;
    magicNumber: number;
  };
}

export function useTaxBands(): ReturnType {
  const [taxBands, setTaxBands] = useState<TaxBandsReadModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAllTaxBands, setShowAllTaxBands] = useState(false);
  const [showAllFields, toggleShowAllFields] = useToggle(false);
  const [tab, setTab] = useState<TaxBandsTab>('list');
  const [error, setError] = useState<ServiceError>();
  const [selectedTaxBands, selectTaxBands] = useSelectedTaxBands({ taxBands });
  const getMagicNumber = useCallback((): number => {
    return Math.random();
  }, []);
  const [magicNumber, setMagicNumber] = useState<number>();

  const service = useTaxBandsService();

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const action = () => (showAllTaxBands ? service.getList() : service.getAvailableList());
      const result = await action();
      setTaxBands(result);
      setMagicNumber(getMagicNumber());
    } catch (e) {
      setError(new ResponseError(e));
      log.error('Cannot get list of Tax Bands', e);
    } finally {
      setLoading(false);
    }
  }, [showAllTaxBands, service, setError, getMagicNumber]);

  useEffect(() => {
    getList();
  }, [showAllTaxBands]); // eslint-disable-line react-hooks/exhaustive-deps

  const addTaxBands = useCallback(
    (taxBands: TaxBandsInfo) => {
      const submit = async () => {
        try {
          setLoading(true);
          const action = () =>
            showAllFields ? service.postWithInitialValues(taxBands) : service.post(taxBands);
          await action();
          setTab('list');
          getList();
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot add Tax Bands', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, showAllFields]
  );

  const removeTaxBands = useCallback(
    (taxBandsId: number) => {
      const submit = async () => {
        try {
          setLoading(true);
          await service.del(taxBandsId);
          setTab('list');
          selectTaxBands({ id: null });
          getList();
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot remove Tax Bands', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectTaxBands]
  );

  const toggleShowAllTaxBands = useCallback(() => {
    setShowAllTaxBands((prev) => !prev);
  }, []);

  const handleChangeTab = useCallback(
    ({ newTab }: { newTab: TaxBandsTab }) => {
      if (tab === 'list' || tab !== newTab) setTab(newTab);
      else setTab('list');
    },
    [tab]
  );

  return {
    actions: {
      addTaxBands,
      toggleShowAllTaxBands,
      handleChangeTab,
      toggleShowAllFields,
      removeTaxBands,
      selectTaxBands,
      getList
    },
    state: {
      taxBands,
      tab,
      showAllFields,
      showAllTaxBands,
      loading,
      error,
      selectedTaxBands,
      magicNumber
    }
  };
}
