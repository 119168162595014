import React, { useState } from 'react';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { ResponseError } from '../../../../../../error/ResponseError';
import { WineProduction } from '../../../../wine/types/WineProduction';
import { useWineStorageService } from '../../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import {
  BottleEntry,
  TankEntry,
  WineStorage,
  WineStorageEntryType
} from '../../../types/WineStorage';
import { useTranslation } from 'react-i18next';

export const withAddWineStorageEntryService =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const service = useWineStorageService();
    const { setResult } = useWineStorageContext();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [entryType, setEntryType] = useState<WineStorageEntryType>();
    const [wineProduction, setWineProduction] = useState<Partial<WineProduction>>({});
    const [tankEntry, setTankEntry] = useState<Partial<TankEntry>>({});
    const [bottleEntry, setBottleEntry] = useState<Partial<BottleEntry>>({});
    const [hasWineProduction, setHasWineProduction] = useState(true);
    const [selectDifferentTank, setSelectDifferentTank] = useState(false);
    const [error, setError] = useState<ServiceError>();

    const updateWineProductionCheckbox = () => {
      setWineProduction({});
      setHasWineProduction((value) => !value);
    };

    const updateTankCheckbox = () => {
      if (!selectDifferentTank) {
        setTankEntry((entry) => ({ ...entry, tank: undefined, liters: undefined }));
      } else {
        setTankEntry((entry) => ({
          ...entry,
          tank: wineProduction.tank,
          liters: wineProduction.liters
        }));
      }
      setSelectDifferentTank((value) => !value);
    };
    const updateWineProduction = (name, selected) => {
      const selectedWineProduction = {
        id: selected.value,
        label: selected.label,
        liters: selected.liters,
        tank: selected.tank
      };
      setWineProduction(selectedWineProduction);
      if (entryType === WineStorageEntryType.TANK) {
        setTankEntry((entry) => ({
          ...entry,
          wineProduction: { id: selectedWineProduction?.id },
          liters: selected.liters,
          tank: selected.tank
        }));
      }
      if (entryType === WineStorageEntryType.BOTTLE) {
        setBottleEntry((entry) => ({
          ...entry,
          wineProduction: { id: selectedWineProduction?.id }
        }));
      }
    };

    const updateEntryType = (name, selected) => {
      setEntryType(selected.value);
      setWineProduction({});
      setTankEntry({});
      setBottleEntry({});
    };

    const resetForm = () => {
      setEntryType(undefined);
      setWineProduction({});
      setTankEntry({});
      setBottleEntry({});
      setHasWineProduction(false);
    };

    const updateLiters = (event: React.ChangeEvent<HTMLInputElement>) => {
      const liters = event.target.value;
      setTankEntry((entry) => ({ ...entry, liters: parseInt?.(liters) }));
    };

    const updateQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = event.target.value;
      quantity && setBottleEntry((entry) => ({ ...entry, quantity: parseInt?.(quantity) }));
    };

    const updateTank = (value) => {
      const selectedTank = { id: value.value, label: value.label };
      setTankEntry((entry) => ({ ...entry, tank: { ...entry.tank, ...selectedTank } }));
    };

    const updateBottle = (value) => {
      const selectedBottle = { id: value.value, label: value.label };
      setBottleEntry((entry) => ({ ...entry, bottle: { ...entry.bottle, ...selectedBottle } }));
    };

    const onSubmit = () => {
      setLoading(true);
      const handleResponse = (response: WineStorage) => {
        setResult({ status: StatusType.loaded, payload: response });
        setLoading(false);
        resetForm();
      };

      const handleError = (response: { message: string; errors: NonNullable<unknown> }) => {
        setError(new ResponseError(response));
        setLoading(false);
      };

      if (hasWineProduction) {
        if (!wineProduction?.id) {
          setError(
            new ResponseError({
              message: t('validation.form.invalid'),
              errors: { wineProduction: t('validation.notNull') }
            })
          );
          setLoading(false);
          return;
        }
      }

      if (entryType === WineStorageEntryType.BOTTLE) {
        service
          .addBottleEntry(bottleEntry)
          .then((response) => {
            handleResponse(response);
          })
          .catch(handleError);
      }
      if (entryType === WineStorageEntryType.TANK) {
        service
          .addTankEntry(tankEntry)
          .then((response) => {
            handleResponse(response);
          })
          .catch(handleError);
      }
    };

    const newProps = {
      ...props,
      hasWineProduction,
      updateWineProductionCheckbox,
      selectDifferentTank,
      updateTankCheckbox,
      entryType,
      updateEntryType,
      tank:
        hasWineProduction && wineProduction?.tank?.id && !selectDifferentTank
          ? wineProduction?.tank
          : tankEntry.tank,
      bottle: bottleEntry.bottle,
      updateTank,
      updateBottle,
      updateQuantity,
      liters: wineProduction?.tank && !selectDifferentTank ? wineProduction?.liters : '',
      updateLiters,
      wineProduction,
      updateWineProduction,
      onSubmit,
      error,
      loading,
      disableTankAndLiters: wineProduction?.tank?.id && !selectDifferentTank
    };

    return <WrappedComponent {...newProps} />;
  };
