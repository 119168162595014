import useFetch from './useFetch';
import { TPagination } from '../features/winery/hooks/pagination/useFetchEntityPage';

interface TCrudApi<T> {
  // eslint-disable-next-line  no-unused-vars
  get;
  getAll: (page: number) => Promise<TPagination<T>>;
  getAllWithParams;
  getList;
  post;
  put;
  putBody;
  putId;
  del;
  delByTwoIds;
}

const useCrudeApi = <T>(endpoint: string): TCrudApi<T> => {
  const crudApi = useFetch<T>(endpoint);

  const getAll = (page: number, status?: string) => {
    return crudApi.getAll(page, status);
  };

  const getAllWithParams = (params: Map<string, string | boolean>) => {
    return crudApi.getAllWithParams(params);
  };

  const getList = () => {
    return crudApi.getList();
  };

  const get = (id: number) => {
    return crudApi.get(id);
  };

  const post = (body: T, params?: Map<string, string | boolean>) => {
    return crudApi.post(body, params);
  };

  const put = (id: number, body: T) => {
    return crudApi.put(id, body);
  };

  const putBody = (body: T) => {
    return crudApi.putBody(body);
  };

  const putId = (id: number) => {
    return crudApi.putId(id);
  };

  const del = (id?: number) => {
    return crudApi.del(id);
  };

  const delByTwoIds = (id: number, secondId: number) => {
    return crudApi.delByTwoIds(id, secondId);
  };

  return {
    get,
    getAll,
    getAllWithParams,
    getList,
    post,
    put,
    putBody,
    putId,
    del,
    delByTwoIds
  };
};

export default useCrudeApi;
