import { useCallback, useState } from 'react';
import { Bottle } from '../../../../winery/components/wine_storage/types/WineStorage';
import { defaultBottle } from './useSelectedBottle';

export type BottleUpdateFormFieldValue = { name: string; value: string | number | Date };

export function useUpdateBottle(
  selectedBottle?: Bottle
): [bottle: Bottle, updateField: ({ name, value }: BottleUpdateFormFieldValue) => void] {
  const [bottle, setBottle] = useState<Bottle>(selectedBottle || defaultBottle);

  const updateField = useCallback(({ name, value }: BottleUpdateFormFieldValue) => {
    setBottle((prev) => ({
      ...prev,
      [name]: value
    }));
  }, []);

  return [bottle, updateField];
}
