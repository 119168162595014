import React, { useState } from 'react';
import PageWrapper from '../../../layouts/PageWrapper';
import { FileItem } from './FileItem';
import { ListItem } from './ListItem';
import { FileItemType, FileListItem, FileType } from '../../../types/FileType';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from '../../../types/Subscription';
import { usePushHistory } from '../../../hooks/usePushHistory';

export const ReportsPage = () => {
  const filesGroups: FileListItem[] = [
    {
      label: 'Winnica',
      type: 'vineyard',
      color: 'green',
      files: [
        {
          title: 'Podsumowanie winnicy',
          type: 'vineyard',
          description: 'Zawiera dane działek i uprawianych winorośli',
          url: '/ajax/pdf/VINEYARD_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.NORMAL
        },
        {
          title: 'Podsumowanie zbiorów',
          type: 'vineyard',
          description:
            'Zawiera dane zbiorów oraz nabytych winogron z aktualnego sezonu winiarskiego',
          url: '/ajax/pdf/CURRENT_HARVESTS?layout=PORTRAIT',
          requiredPlan: SubscriptionType.NORMAL
        }
      ]
    },
    {
      label: 'Winiarnia',
      type: 'winery',
      color: 'info',
      files: [
        {
          title: 'Podsumowanie winiarni',
          type: 'winery',
          description: 'Zawiera ogólne dane dotyczące aktualnej produkcji wina',
          url: '/ajax/pdf/WINERY_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.NORMAL
        },
        {
          title: 'Podsumowanie zbiorników',
          type: 'winery',
          description: 'Zawiera listę zbiorników na winiarni wraz z danymi',
          url: '/ajax/pdf/TANKS_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.NORMAL
        },
        {
          title: 'Wykaz dostępnych składników',
          type: 'winery',
          description: 'Zawiera listę dostępnych na winiarni składników wraz z krótkim opisem',
          url: '/ajax/pdf/INGREDIENTS_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.NORMAL
        },
        {
          title: 'Zbiorniki w użyciu na winiarni',
          type: 'winery',
          description: 'Zawiera listę aktualnie zapełnionych zbiorników na winiarni wraz z danymi',
          url: '/ajax/pdf/TANKS_WINERY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        }
      ]
    },
    {
      label: 'Piwnica',
      type: 'storage',
      color: 'warning',
      files: [
        {
          title: 'Podsumowanie piwnicy',
          type: 'storage',
          description: 'Zawiera ogólne dane dotyczące win w piwnicy oraz ich podsumowanie',
          url: '/ajax/pdf/STORAGE_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Podsumowanie piwnicy (grupy win)',
          type: 'storage',
          description:
            'Zawiera podsumowanie piwnicy z podziałem na grupy win: odmianowe, wina z ChNP lub ChOG oraz pozostałe',
          url: '/ajax/pdf/STORAGE_SUMMARY_BY_GROUP?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Wina w piwnicy',
          type: 'storage',
          description: 'Zawiera listę wszystkich win znajdujących się w piwnicy',
          url: '/ajax/pdf/STORAGE_WINES?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Wina odmianowe w piwnicy',
          type: 'storage',
          description: 'Zawiera listę win odmianowych znajdujących się w piwnicy',
          url: '/ajax/pdf/STORAGE_VARIETAL_WINES?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Wina z ChNP lub ChOG w piwnicy',
          type: 'storage',
          description:
            'Zawiera listę win z Chronioną Nazwą Pochodzenia lub z Chronionym Oznaczeniem Geograficznym znajdujących się w piwnicy',
          url: '/ajax/pdf/STORAGE_PROTECTED_WINES?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Pozostałe wina w piwnicy',
          type: 'storage',
          description:
            'Zawiera listę wszystkich pozostałych win w piwnicy (niebędących winami odmianowymi ani z ChNP lub ChOG',
          url: '/ajax/pdf/STORAGE_OTHER_WINES?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Zbiorniki w użyciu w piwnicy',
          type: 'storage',
          description: 'Zawiera listę aktualnie zapełnionych zbiorników w piwnicy wraz z danymi',
          url: '/ajax/pdf/TANKS_STORAGE?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Lista wydanych banderol',
          type: 'storage',
          description: 'Zawiera listę dostępnych i wykorzystanych banderol podatkowych',
          url: '/ajax/pdf/TAX_BANDS_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Lista butelek',
          type: 'storage',
          description: 'Zawiera listę dostępnych i zarchiwizowanych butelek',
          url: '/ajax/pdf/BOTTLES_SUMMARY?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        }
      ]
    },
    {
      label: 'Deklaracje KOWR',
      type: 'kowr',
      color: 'purple',
      files: [
        {
          title: 'Deklaracja o zbiorach winogron do 15 stycznia',
          type: 'kowr',
          description:
            'Zawiera dane niezbędne do złożenia Deklaracji o zbiorach winogron w danym roku winiarskim',
          url: '/ajax/pdf/KOWR_JANUARY_15_1?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Deklaracja o produkcji wina do 15 stycznia',
          type: 'kowr',
          description:
            'Zawiera dane niezbędne do złożenia Deklaracji o produkcji wina w danym roku winiarskim',
          url: '/ajax/pdf/KOWR_JANUARY_15_2?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        },
        {
          title: 'Deklaracja o zapasach win i moszczów do 31 sierpnia',
          type: 'kowr',
          description:
            'Zawiera dane niezbędne do złożenia Deklaracji o zapasach win i moszczów winogronowych',
          url: '/ajax/pdf/KOWR_SEPTEMBER_31?layout=LANDSCAPE',
          requiredPlan: SubscriptionType.PREMIUM
        }
      ]
    }
  ];

  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<FileType>('all');
  const [selectedFiles, setSelectedFiles] = useState(getAllFiles);
  const { pushHistory } = usePushHistory();

  function getAllFiles(): FileItemType[] {
    return filesGroups.flatMap((group) => group.files);
  }

  function handleChangeGroup(item: FileListItem) {
    setSelectedType(item.type);
    setSelectedFiles(filesGroups.find((group) => group.type === item.type).files);
  }

  function handleAllGroups() {
    setSelectedType('all');
    setSelectedFiles(getAllFiles);
  }

  return (
    <PageWrapper
      title={'reports.TITLE'}
      loading={false}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <div className="card-body">
              <strong className="text-muted">{t('file.manager.types')}</strong>
            </div>
            <div className="list-group">
              <ListItem
                label={'Wszystkie'}
                value={getAllFiles().length}
                active={'all' === selectedType}
                color={'grey'}
                onClick={() => handleAllGroups()}
              />
              {filesGroups.map((item, index) => (
                <ListItem
                  label={item.label}
                  value={item.files.length}
                  active={item.type === selectedType}
                  color={item.color}
                  key={item.type}
                  onClick={() => handleChangeGroup(item)}
                  isLast={index === filesGroups.length - 1}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="row">
            {selectedFiles.map((file) => (
              <FileItem
                title={file.title}
                type={file.type}
                description={file.description}
                url={file.url}
                key={file.type + file.title}
                requiredPlan={file.requiredPlan}
              />
            ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
