import React from 'react';
import { AddPlacementPresentation } from './AddPlacementPresentation';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { useTranslation } from 'react-i18next';
import { useUpdatePlacementForm } from '../hooks/useUpdatePlacementForm';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="mb-2"></div>
      <small className="text-muted ">{t('taxBands.board.addPlacement')}</small>
    </div>
  );
};

export const PlacementFormComponent: React.FC<{
  selectTaxBands: ({ id }: { id: number }) => void;
  fetchTaxBandsList: () => void;
  getPlacementsForTaxBands: (taxBandsId: number) => void;
}> = ({ fetchTaxBandsList, getPlacementsForTaxBands, selectTaxBands }) => {
  const {
    state: { loading, error, selectedTaxBands, taxBandsPlacement },
    actions: { onSubmit, updateDate, updateQuantity, updateSelect, updateSelectedTaxBands }
  } = useUpdatePlacementForm({ selectTaxBands, fetchTaxBandsList, getPlacementsForTaxBands });

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header />
          <AddPlacementPresentation
            taxBandsPlacement={taxBandsPlacement}
            updateDate={updateDate}
            updateQuantity={updateQuantity}
            error={error}
            onSubmit={onSubmit}
            updateSelect={updateSelect}
            updateSelectedTaxBands={updateSelectedTaxBands}
            selectedTaxBands={selectedTaxBands}
            key={selectedTaxBands?.id}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};
